<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Föy İptal İstekleri
        </b-card-title>
      </b-card-header>
      <template v-if="dataList.length > 0">
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(customer)="data">
            <div v-if="data.item.order_number">
              <small
                class="text-primary"
              >
                <b-link
                  :href="'/sale_orderforms/view/'+data.item.order_number"
                  target="_blank"
                >{{ data.item.order_number }}</b-link></small>
            </div>
            {{ data.item.customer }}
            <div v-if="data.item.request">
              <small class="text-warning">{{ data.item.request }}</small>
            </div>
          </template>
          <template #cell(username)="data">
            {{ data.item.username }}
            <div v-if="data.item.created">
              <small class="text-warning">{{ moment(data.item.created).format('DD.MM.YYYY') }}</small>
            </div>
          </template>
          <template #cell(status)="data">
            <div v-if="data.item.status === '1'">
              <p>
                <feather-icon icon="ClockIcon" />
                Beklemede
              </p>
            </div>
            <div v-if="data.item.status === '2'">
              <p class="text-success">
                <feather-icon icon="CheckCircleIcon" />
                Onaylandı
              </p>
            </div>
            <div v-if="data.item.status === '3'">
              <p class="text-danger">
                <feather-icon icon="XCircleIcon" />
                Reddedildi
              </p>
              <div v-if="data.item.decline_text">
                <hr>
                <small class="text-muted">{{ data.item.decline_text }}</small>
              </div>
            </div>
          </template>
          <template #cell(control)="data">
            <b-dropdown
              text="İşlemler"
              variant="primary"
              size="sm"
              :disabled="data.item.status !== '1'"
              dropleft
            >
              <b-dropdown-item
                v-if="data.item.status === '1'"
                @click="acceptRequest(data.item.id,data.item.id_com_sale_orderform)"
              >
                <feather-icon icon="CheckCircleIcon" />
                Onayla
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status === '1'"
                @click="showDeclineModal(data.item.id,data.item.id_com_sale_orderform)"
              >
                <feather-icon icon="XCircleIcon" />
                Reddet
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </template>
      <template v-else>
        <b-alert
          show
          variant="info"
          class="m-2"
        >
          <div class="alert-body text-center">
            Hiç iptal isteğiniz yok. 👏
          </div>
        </b-alert>
      </template>
    </b-card>
    <b-modal
      v-model="declineModal.status"
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Red Nedeniniz"
    >
      <b-form-group
        label="Föy iptal red nedeniniz"
        label-for="decline_text"
      >
        <b-form-textarea
          id="reason"
          v-model="declineModal.decline_text"
          placeholder="Giriniz.."
        />
      </b-form-group>
      <template #modal-footer>
        <b-button
          :disabled="!declineModal.decline_text"
          variant="success"
          @click="declineRequest(declineModal.id,declineModal.index)"
        >
          <feather-icon icon="SaveIcon" />
          Kaydet
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BCardTitle,
  BCardHeader,
  BTable,
  BCardFooter,
  BPagination,
  BDropdownItem,
  BDropdown,
  BLink,
  BFormGroup,
  BModal,
  BButton, BFormTextarea, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'DeleteRequests',
  components: {
    BAlert,
    BFormTextarea,
    BButton,
    BModal,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardFooter,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BLink,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      dataQuery: {
        limit: 10,
        start: 0,
      },
      declineModal: {
        status: false,
        decline_text: null,
        id: null,
        index: null,
      },
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
          thClass: 'font-small-2 table-middle text-nowrap w-50',
          tdClass: 'font-small-2 text-nowrap',
        },

        {
          key: 'username',
          label: 'Bildirimi Yapan',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'status',
          label: 'Durum',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['saleOrderformsCancelPendings/dataList']
    },
    dataCount() {
      return this.$store.getters['saleOrderformsCancelPendings/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('saleOrderformsCancelPendings/getDataList', this.dataQuery)
    },
    acceptRequest(id, index) {
      this.$swal({
        title: 'İsteği Onayla',
        text: 'İstek onaylandığında ilgili föy iptal edilecek ve bu işlem geri alınamayacak. Devam etmek istiyor musunuz ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('saleOrderformsCancelPendings/acceptRequest', { id_com_com_sale_orderform_delete_pending: id, id_com_sale_orderform: index })
            .then(res => {
              if (res.status) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız!',
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    showDeclineModal(id, index) {
      this.declineModal.status = true
      this.declineModal.decline_text = null
      this.declineModal.id = id
      this.declineModal.index = index
    },
    declineRequest(id, index) {
      this.declineModal.status = false
      this.$swal({
        title: 'İsteği reddetmek istiyor musunuz ?',
        text: 'İşlem geri alınamayacak ve bildirimi gönderen kullanıcı bilgilendirilecek.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('saleOrderformsCancelPendings/declineRequest', { id_com_com_sale_orderform_delete_pending: id, id_com_sale_orderform: index, decline_text: this.declineModal.decline_text })
            .then(res => {
              if (res.status) {
                this.$swal({
                  icon: 'success',
                  title: 'İstek Reddedildi!',
                  text: 'İstek başarı ile reddedildi.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'İşlem Başarısız!',
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
